/* ...existing code... */

.switch-icon .iconify {
  /* ...existing styles... */
}

@media (max-width: 768px) {
  .react-switch {
    margin-top: 35px !important;
  }
}
