@import '../src/scss/themes/theme-dark.scss';
@import '../src/scss/themes/theme-light.scss';

@import url('https://cdn.jsdelivr.net/npm/@xz/fonts@1/serve/source-code-pro.min.css');
@import url('https://fonts.cdnfonts.com/css/ua-squared');

@font-face {
  font-family: 'NEXT ART';
  src:
    local('NEXT ART'),
    url('../public/fonts/NEXT_ART_Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

:root {
  --primary-color: #233d4d;
  --secondary-color: #edf2ef;
  --primary-color-darker: #13212a;
  --grey-color: #b8b8b8;
}

html {
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
}

// body {
//   cursor: url('/cursor.png'), auto;
// }

.github-corner:hover .octo-arm {
  animation: octocat-wave 560ms ease-in-out;
}
@keyframes octocat-wave {
  0%,
  100% {
    transform: rotate(0);
  }
  20%,
  60% {
    transform: rotate(-25deg);
  }
  40%,
  80% {
    transform: rotate(10deg);
  }
}
@media (max-width: 500px) {
  .github-corner:hover .octo-arm {
    animation: none;
  }
  .github-corner .octo-arm {
    animation: octocat-wave 560ms ease-in-out;
  }
}
.aligner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-container {
  height: 50px;
}

.title-styles {
  font-family: 'Source Code Pro', sans-serif;
  font-size: 250%;
}

.header-icon {
  height: 150px;
  width: 150px;
  color: var(--secondary-color);
}

header {
  position: relative;
  height: 520px;
  min-height: 450px;
  width: 100%;
  background-size: cover;
  -webkit-background-size: cover;
  text-align: center;
  overflow: hidden;
  background-color: var(--primary-color);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-top: 150px;
  padding-bottom: 0;
  .construction-banner {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 25%;
    background-color: rgb(29, 29, 29);
    color: rgb(140, 140, 140);
    padding: 10px;
    text-align: center;
    border-radius: 0px 10px;
    padding: 10px 0;
    z-index: 1000;
    font-size: 18px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
    opacity: 0.4;
    @media (max-width: 500px) {
      display: none;
    }
  }
  .title-container {
    margin-bottom: 20px;
  }
  .header-name {
    font-family: 'UA Squared', sans-serif;
    letter-spacing: 2px;
    font-size: 500%;
  }
  h1,
  .header-icon,
  .header-name,
  .title-container,
  .react-switch {
    color: var(--secondary-color);
    position: relative;
    z-index: 2;
  }
  .react-switch {
    margin-top: 30px;
  }
  .react-switch-handle {
    background-color: color-mix(
      in srgb,
      var(--secondary-color) 70%,
      transparent
    ) !important;
  }
  .credits {
    position: absolute;
    z-index: 2;
    font-size: 12px;
    color: var(--grey-color);
    bottom: 10px;
    left: 10px;
    opacity: 0.65;
    text-transform: lowercase;
    @media (max-width: 500px) {
      top: 10px;
      right: 10px;
    }
    a,
    i {
      color: var(--grey-color);
      svg {
        margin-left: 2px;
        margin-bottom: 2px;
        opacity: 0.9;
      }
    }
  }
  @media (max-width: 400px) {
    .title-container {
      margin-bottom: 50px;
    }
    .header-name {
      letter-spacing: 1.8px;
      font-size: 450%;
    }
  }
}

.image-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: var(--primary-color);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.8);
  }

  .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); // Adjust opacity as needed
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px); // Safari support
  }
}

#srolldown-container {
  cursor: pointer;
}

.scrolldown-arrow {
  cursor: pointer;
  width: 2em;
  height: 2em;
  background-color: transparent;
  z-index: 80;
  position: absolute;
  border-width: 0 0.25em 0.25em 0;
  border-style: solid;
  border-color: antiquewhite;
  animation: scrolldown 1.6s ease-in-out infinite 0.15s;
  left: 50%;
  transform: translateY(0%) rotate(45deg);
  opacity: 0;
  @media (max-width: 500px) {
    left: 49%;
  }
}

@keyframes scrolldown {
  0% {
    transform: translateY(20%) rotate(45deg);
    opacity: 0.7;
  }
  50% {
    transform: translateY(0%) rotate(45deg);
    opacity: 0.2;
  }
  100% {
    transform: translateY(20%) rotate(45deg);
    opacity: 0.7;
  }
}

.project-date {
  font-size: 16px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding: 8px 15px 2px 15px;
  position: relative;
  z-index: -500;
  border-top: 5px solid #696969;
  border-radius: 0 0 5px 5px;
  background-color: #696969;
  color: var(--secondary-color);
}

.skills-tile {
  background-color: color-mix(
    in srgb,
    var(--primary-color-darker) 80%,
    transparent
  );
  padding: 10px 10px 5px 10px;
  width: 100px;
  margin: 5px 0 5px 0;
  border-radius: 8px;
}

header h1 {
  font-size: 400%;
  text-align: center;
  font-weight: 600 !important;
  color: #353239;
  color: #f8f5f4;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  margin: 0 auto 18px auto;
  width: 100%;
}

.slider-image {
  border: 5px solid var(--primary-color);
  cursor: pointer;
}

.slider-tab {
  background-color: var(--primary-color);
  height: 25px;
}

.slider-iconfiy {
  margin-top: 10px;
}

.styles_typicalWrapper__1_Uvh::after {
  cursor: none !important;
  display: none;
}

#about {
  background: var(--primary-color);
  overflow: hidden;
}

#about h1 {
  padding-top: 5%;
  font:
    18px/24px 'opensans-bold',
    sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: var(--secondary-color);
  text-align: center;
}

.foto img {
  display: block;
  width: 100%;
}

.foto div {
  background: var(--primary-color) !important;
  display: inline-block;
  margin: 0 auto 5% auto;
  padding: 10px 10px 5px;
  text-align: center;
  text-decoration: none;
  -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  transition: all 0.2s linear;
  z-index: 0;
  position: relative;
}

.foto div:after {
  color: #333;
  font-size: 25px;
  content: attr(title);
  position: relative;
  top: 15px;
}

.foto div:hover {
  -webkit-transform: scale(1.01);
  -moz-transform: scale(1.01);
  transform: scale(1.01);
  z-index: 10;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7);
}

.project-title-settings {
  margin-top: 5%;
  font:
    18px/24px 'opensans-bold',
    sans-serif;
  font-family:
    'NEXT ART',
    -apple-system,
    BlinkMacSystemFont,
    sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: var(--secondary-color);
  text-align: center;
}

.polaroid img {
  display: block;
  max-width: 200px;
}

.polaroid span {
  background: var(--secondary-color);
  display: inline-block;
  margin: 55px 75px 30px;
  padding: 15px 15px 30px;
  text-align: center;
  text-decoration: none;
  -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  transition: all 0.2s linear;
  z-index: 0;
  position: relative;
}

.card-body {
  background-color: var(--secondary-color);
  color: var(--primary-color-darker);
}

.project-container {
  filter: drop-shadow(2px 2px 5px #8c8c8c);

  div.foto div {
    border-radius: 3px;
  }
  img {
    border-radius: 6px;
  }
}

.modal-description {
  font-weight: 500;
  color: var(--primary-color);

  color: var(--primary-color);
}

.link-href {
  color: black;
}

.wave {
  font-size: 160%;
}

.font-trebuchet {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

#preview {
  width: 500px;
  background-color: #ebeaf5;
  padding: 15px;
  position: relative;
  margin-bottom: 15%;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

#resume {
  background: var(--secondary-color);
  .section-title {
    color: var(--primary-color);
  }
}

.experience-icon {
  font-size: 300%;
  margin-top: 25%;
  text-align: center;
}

.main-badge {
  font-size: 13px !important;
  text-align: left !important;
  padding: 5px 8px 5px 8px !important;
  vertical-align: baseline;
  background-color: #ae944f !important;
  color: white;
  font-weight: lighter !important;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.experience-badge {
  font-size: 11px !important;
  text-align: left !important;
  padding: 5px 8px 5px 8px !important;
  margin: 1px 2px;
  vertical-align: baseline;
  background-color: var(--secondary-color) !important;
  color: var(--primary-color-dark) !important;
  font-weight: lighter !important;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  border-style: solid;
  border-width: 1px;
  border-color: #ccc;
}

.vertical-timeline-element-date {
  padding: 0 !important;
  margin: 0 !important;
  color: var(--primary-color) !important;
}

.vertical-timeline-element-icon,
.vertical-timeline-element-subtitle {
  cursor: pointer;
}

.vertical-timeline-element-title,
.vertical-timeline-element-subtitle {
  color: var(--primary-color) !important;
}

.experience-logo {
  width: 85%;
  height: 85%;
  object-fit: contain;
  padding-top: 7px;
}

.modal-inside .modal-content {
  background: var(--secondary-color);
  .skills-icon {
    color: var(--primary-color);
  }
}

.bars {
  width: 95%;
  float: left;
  padding: 0;
  text-align: left;
}

.bars .skills {
  margin-top: 36px;
  list-style: none;
}

.bars li {
  position: relative;
  margin-bottom: 60px;
  background: #ccc;
  height: 42px;
  border-radius: 3px;
}

.bars li em {
  font:
    15px 'opensans-bold',
    sans-serif;
  color: #313131;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: normal;
  position: relative;
  top: -36px;
}

.bar-expand {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  padding-right: 24px;
  background: #313131;
  display: inline-block;
  height: 42px;
  line-height: 42px;
  border-radius: 3px 0 0 3px;
}

.modal-close {
  text-align: right;
  padding: 10px 15px 10px 15px;
  cursor: pointer;
}

.close-icon {
  color: var(--primary-color);
  font-weight: lighter !important;

  -webkit-transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  &:hover {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

.modal-description {
  text-align: justify;
  padding: 5px 5px 0 5px;
  margin-bottom: 20px;
  font-size: 12px;
}

.awssld__next,
.awssld__prev {
  color: var(--primary-color) !important;
  outline: none !important;
}

.loader-bar-color {
  color: var(--primary-color) !important;
}

#portfolio {
  background: var(--secondary-color);
  padding-bottom: 5%;
  .section-title {
    color: var(--primary-color);
  }
}

.portfolio-item {
  max-width: 100%;
  margin-bottom: 15px;
  text-align: center;
}

.portfolio .portfolio-item .portfolio-item-caption {
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
  transition: all ease 0.5s;
  opacity: 0;
  background-color: rgba(51, 51, 51, 0.9);
}

.portfolio .portfolio-item .portfolio-item-caption:hover {
  opacity: 1;
}

.portfolio
  .portfolio-item
  .portfolio-item-caption
  .portfolio-item-caption-content {
  font-size: 1.5rem;
}

@media (min-width: 576px) {
  .portfolio .closeButtonResponsive {
    display: block;
  }
  .portfolio .portfolio-item {
    margin-bottom: 30px;
  }
}

#skills {
  background: var(--primary-color);
  min-height: 200px;
  width: 100%;
  overflow: hidden;
  padding-bottom: 10%;
}

.section-title {
  padding-top: 5%;
  padding-bottom: 5%;
  font:
    18px/24px 'opensans-bold',
    sans-serif;
  font-family:
    'NEXT ART',
    -apple-system,
    BlinkMacSystemFont,
    sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: var(--secondary-color);
  text-align: center;
}

.skill-icon {
  font-size: 180%;
  color: var(--secondary-color);
  text-align: center;
  position: relative;
}

footer {
  background: var(--primary-color);
  min-height: 100px;
  width: 100%;
  overflow: hidden;
  font-size: 14px;
  color: white;
  position: relative;
  text-align: center;
}

footer a,
footer a:visited {
  color: var(--secondary-color);
}

footer a:hover,
footer a:focus {
  color: var(--secondary-color);
}

.social-links {
  margin-top: 50px;
  font-size: 22px;
}
