body[data-theme='dark'] {
  @import '../dark-slider.scss';

  .github-corner svg {
    .octo-body,
    .octo-arm {
      color: var(--grey-color);
    }
    color: #151513;
  }

  .image-background {
    // position: absolute;
    // top: 0;
    // left: 0;
    // width: 100%;
    // height: 100%;
    // z-index: 1;
    // background-color: var(--primary-color);

    img {
      // width: 100%;
      // height: 100%;
      // object-fit: cover;
      // filter: brightness(0.5);
    }

    .image-overlay {
      // position: absolute;
      // top: 0;
      // left: 0;
      // width: 100%;
      // height: 100%;
      // background: rgba(0, 0, 0, 0.5); // Adjust opacity as needed
      // backdrop-filter: blur(5px);
      // -webkit-backdrop-filter: blur(5px); // Safari support
    }
  }

  .slider-tab {
    background-color: var(--secondary-color);
  }
  header {
    .header-icon,
    .header-name,
    .title-styles {
      color: var(--grey-color);
    }

    .react-switch-handle {
      background-color: #d2d2d2 !important;
    }

    .scrolldown-arrow {
      border-color: var(--grey-color);
    }
  }

  .slider-image {
    border: 5px solid var(--secondary-color);
  }

  .link-href {
    color: white;
  }

  .project-date {
    background-color: #918e97;
    color: white;
  }

  #about {
    //     background-color: var(--primary-color-darker);
    .polaroid span {
      background-color: var(--primary-color-darker);
      border: 1px solid var(--grey-color);
    }
    // .card {
    //   background: var(--secondary-color);
    // }
    .card-header {
      background-color: var(--primary-color);
      border: 1px solid var(--grey-color) !important;
    }
    .card-body {
      background-color: var(--primary-color-darker);
      color: var(--secondary-color);
    }
  }

  #portfolio {
    background: var(--primary-color-darker);
    .card {
      background-color: var(--secondary-color);
    }
    .section-title {
      color: var(--secondary-color) !important;
    }
    .foto div {
      background: #6d6d6d;
    }
    .project-title-settings {
      color: white;
    }
    .project-date {
      background-color: var(--primary-color-darker);
    }
  }

  #resume {
    background: var(--primary-color-darker);
    .section-title {
      color: var(--secondary-color) !important;
    }

    .experience-badge {
      background: var(--secondary-color) !important;
      color: var(--primary-color-darker) !important;
    }

    .main-badge {
      background: var(--primary-color) !important;
    }

    .vertical-timeline-element-date {
      color: var(--secondary-color) !important;
    }

    .vertical-timeline-element-icon {
      background: var(--secondary-color);
      color: var(--primary-color);
    }
    @media only screen and (max-width: 1169px) {
      .vertical-timeline-element-date {
        color: black;
      }
    }
  }

  .modal-inside .modal-content {
    background: var(--primary-color-darker);
    color: var(--secondary-color);
    .modal-description {
      color: var(--secondary-color);
    }
    .skills-icon {
      color: var(--secondary-color);
    }
  }

  .close-icon {
    color: var(--secondary-color);
  }

  .awssld__next,
  .awssld__prev {
    color: var(--secondary-color) !important;
    outline: none !important;
  }
}
